import React from "react";
import './timeline.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlane} from "@fortawesome/free-solid-svg-icons/faPlane";

function TimeLine(props:any) {
    const leftPosition = true;
    const elements = props.elements;
    return (<div className="timeline">
        {
            elements.map((element: any)=>{
                console.log(element);
                const className = (leftPosition ? "timeline-box left" : "timeline-box right");
                return (<div className={className}>
                    <div className="timeline-container">
                        <div className="timeline-logo">
                            <img src={element.logo}
                                 alt="experience"/>
                        </div>
                        <h3 className="experience-designation  m0 m-blue"> {element.designation} </h3>
                        <h4 className="experience-company-name"> {element.companyName} </h4>
                        <h5 className="experience-duration m0"> {element.experience}</h5>

                        <p className="experience-description text-align-justify"> {element.description} </p>
                    </div>
                </div>)
            })
        }
        <div id="timeline-divider">
            <div className="timeline-traveller">
                <div>
                    <i><FontAwesomeIcon icon={faPlane} size="2x" /></i>
                </div>
            </div>
        </div>
    </div>);
}

export default TimeLine;