import React from "react";
import SectionHeader from "../SectionHeader/SectionHeader";
import {faChalkboardTeacher} from '@fortawesome/free-solid-svg-icons';
import Skill from "./Skill/Skill";

import './Skills.css';

function Skills() {
    const skills = [{
        "skillName": "C",
        "skillPercentage": "50",
        "skillPercentageClass": "fifty-percent",
        "skillColorClass": "mb-blue"
    },
        {
            "skillName": "C++",
            "skillPercentage": "50",
            "skillPercentageClass": "fifty-percent",
            "skillColorClass": "mb-blue"
        },
        {
            "skillName": "Java",
            "skillPercentage": "50",
            "skillPercentageClass": "fifty-percent",
            "skillColorClass": "mb-blue"
        }, {
            "skillName": "Spring Boot",
            "skillPercentage": "50",
            "skillPercentageClass": "fifty-percent",
            "skillColorClass": "mb-blue"
        }, {
            "skillName": "Mysql",
            "skillPercentage": "50",
            "skillPercentageClass": "fifty-percent",
            "skillColorClass": "mb-blue"
        }];
    return (
        <section className="skills" id="skills">
            <SectionHeader icon={faChalkboardTeacher} name="skills"/>
            <div id="skills-container" className="skills-display">{skills.map((skill, index) => {
                return <Skill skillName={skill.skillName} skillPercentage={skill.skillPercentage}
                              skillPercentageClass={skill.skillPercentageClass} skillColorClass={skill.skillColorClass}
                              key={index}/>
            })}</div>
        </section>
    );
}

export default Skills;