import React from "react";
import SectionHeader from "../SectionHeader/SectionHeader";
import {faBriefcase} from "@fortawesome/free-solid-svg-icons/faBriefcase";
import './expirence.css';
import TimeLine from "../../TimeLine/TimeLine";
import EnKashLogo from '../../images/enkash.png';

function Experiences() {
    const experiences = [
    {
        "logo": EnKashLogo,
        "designation": "Senior Software Developer",
        "companyName": "EnKash",
        "experience": "June 2023 - Present",
        "description": ""
    },
    {
        "logo": EnKashLogo,
        "designation": "Software Developer",
        "companyName": "EnKash",
        "experience": "July 2022 - May 2023",
        "description": ""
    },
    {
        "logo": EnKashLogo,
        "designation": "Junior Software Developer",
        "companyName": "EnKash",
        "experience": "August 2020 - June 2022",
        "description": ""
    }];
    return (<section className="experience" id="experience">
        <SectionHeader icon={faBriefcase} name=" Work Experience "/>
        <TimeLine elements={experiences}/>
    </section>);
}

export default Experiences;