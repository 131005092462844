import React from "react";
import SectionHeader from "../SectionHeader/SectionHeader";
import {faBriefcase} from "@fortawesome/free-solid-svg-icons/faBriefcase";
import TimeLine from "../../TimeLine/TimeLine";
import NitKkrLogo from '../../images/nitkkrlogo.png';
import CodingNinjasLogo from '../../images/codingninjas.png';
import DucatLogo from '../../images/ducatlogo.png';
import NiitLogo from '../../images/niitlogo.png';


function Education() {
    const educations = [
        {
            "logo": NitKkrLogo,
            "designation": "Student",
            "companyName": "NIT Kurukshetra",
            "experience": "July 2015 - July 2019",
            "description": "Bachelor of Technology in Information Technology"
        },
        {
            "logo": CodingNinjasLogo,
            "designation": "Student",
            "companyName": "Coding Ninjas",
            "experience": "June 2018 - July 2018",
            "description": "DSA in C++"
        },
        {
            "logo": DucatLogo,
            "designation": "Student",
            "companyName": "Ducat",
            "experience": "June 2017 - July 2017",
            "description": "n+ and ccna training"
        },
        {
            "logo": NiitLogo,
            "designation": "Student",
            "companyName": "NIIT",
            "experience": "June 2016 - July 2016",
            "description": "Training in C++"
        }];
        return (<section className="education" id="education">
            <SectionHeader icon={faBriefcase} name=" Education "/>
            <TimeLine elements={educations}/>
        </section>);
}

export default Education;